import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Routes } from './appRoutes';
import ProtectRoute from './protectRoute';
import {
  Home,
  About,
  Service,
  Works,
  Gallery,
  Features,
  Contact,
} from '../pages/index';

const Routers = createBrowserRouter([
  {
    path: Routes.emptyPath,
    element: <ProtectRoute component={Home} {...Routes.home} />,
  },
  {
    path: Routes.about.path,
    element: <ProtectRoute component={About} {...Routes.about} />,
  },
  {
    path: Routes.contact.path,
    element: <ProtectRoute component={Contact} {...Routes.contact} />,
  },
  {
    path: Routes.services.path,
    element: <ProtectRoute component={Service} {...Routes.services} />,
  },
  {
    path: Routes.works.path,
    element: <ProtectRoute component={Works} {...Routes.works} />,
  },
  {
    path: Routes.gallery.path,
    element: <ProtectRoute component={Gallery} {...Routes.gallery} />,
  },
  {
    path: Routes.features.path,
    element: <ProtectRoute component={Features} {...Routes.features} />,
  },
  
]);

export default Routers;
