import React from 'react'
import {Footer_Logo, Footer_Background, FooterIcon_Call, FooterIcon_Msg, FooterIconFaceBook, FooterIconLinkedin, FooterIconTwiter} from '../assets/images/index'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer  className='nec_footer_container'>
        <div  className='nec_footer_container-background'>
                <img src={Footer_Background} alt="Footer_Background" width="100%" height="100%"/>
        </div>

        <div  className='nec_footer'>
        <div className='nec_footer_logo'>
            <div className='nec_footer_logo_img'>
                <img src={Footer_Logo} alt="Footer_Logo"/>
            </div>
            <p>
            Our Coating are used in wherever erosion, corrosion, 
wear & chemical attach create problems in fluid flow 
systems and will provide the ultimate solution.
            </p>
        </div>

        <div className='nec_footer_location'>
        <h4>Location</h4>
        <p>
        F4,Sree Hari Tower, 
        Rainbow Colony,
        Kelambakkam, 
        Kanchipuram, 
        Tamil Nadu - 603 103.
        </p>
        </div>
        <div className='nec_footer_links'>
        <h4>Quick Links</h4>
        <ul>
        <li><NavLink to="/" exact activeClassName>Home</NavLink></li>
        <li><NavLink to="/about" exact activeClassName>About Us</NavLink></li>
        <li><NavLink to="/services" exact activeClassName>Services</NavLink></li>
        <li><NavLink to="/works" exact activeClassName>Our Works</NavLink></li>
        <li><NavLink to="/contact" exact activeClassName>Contact Us</NavLink></li>
        </ul>
        </div>
        <div className='nec_footer_products'>
        <h4>Our Products</h4>
        <p> Industrial Solutions</p>
        <p>Water Proofing</p>
        <p>Dam Rehabilitation</p>
        </div>
        <div className='nec_footer_contact'>
        <h4>Contact Us</h4>
        <div className='nec_footer_contact-icon'><img src={FooterIcon_Call}  alt="Contact Us"/><p>+91 98402 72131</p></div>
        <div className='nec_footer_contact-icon'><img src={FooterIcon_Msg}  alt="Contact Us"/><p>info@naveenengg.in</p></div>
        </div>
        </div>

        <div  className='nec_footer_socialmedia-icons'>
           <div  className='nec_footer_socialmedia-icons-img'><a href='https://www.facebook.com'><img src={FooterIconFaceBook} alt="socialmedia icon"/></a></div>
           <div  className='nec_footer_socialmedia-icons-img'><a href='https://www.linkedin.com'><img src={FooterIconLinkedin} alt="socialmedia icon"/></a></div>
           <div  className='nec_footer_socialmedia-icons-img'><a href='https://twitter.com'><img src={FooterIconTwiter} alt="socialmedia icon"/></a></div>
        </div>
        <div className='nec_footer_copyright'><p>Copyright © 2024 Naveen Engineering and Speciality Coating Pvt Ltd. All rights reserved.</p></div>
    </footer>
  )
}

export default Footer