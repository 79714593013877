import React, { useState, useEffect } from 'react';
import AuthLayout from '../layout/AuthLayout';

const ProtectRoute = ({
  component: Component,
  ...props
}) => {
  console.log(props,"")
  return(
    <AuthLayout
      {...props}
    >
      <Component
        {...props}
      />
    </AuthLayout>
  )
};

export default ProtectRoute;
