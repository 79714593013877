import React from 'react'
import Carousel from "react-bootstrap/Carousel";
const Gallery = () => {
  return ( <>
        <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide gallery_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Gallery</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide gallery_slide2">
            <div className="carousel_secound_slide_content">
              <h2>Gallery</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide gallery_slide3">
            <div className="carousel_secound_slide_content">
              <h2>Gallery</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="gallery_container">
        <header className="gallery_container-header">
          <h4>VIEW ALL</h4>
          <h4>INDUSTRIAL SOLUTIONS</h4>
          <h4>WATER PROOFING</h4>
          <h4>DAM REHABILITATION</h4>
        </header>
          <div  className="gallery_first_row">
            <div className="gallery_first_row-img1">
              <div  className="galary_contant_data">
              <h4>Corrosion Protector</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
            </div>
            <div className="gallery_first_row-img2">
            <div className="galary_contant_data">
              <h4>Corrosion Protector</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
            </div>
          </div>

          <div  className="gallery_secound_row">
            <div className="gallery_secound_row-img gallery_secound_row-firstimg ">
            <div className="galary_contant_data">
              <h4>Corrosion Protector</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
            </div>
            <div className="gallery_secound_row-img gallery_secound_row-secoundimg">
            <div className="galary_contant_data">
              <h4>Water Proofing</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
            </div>
            <div className="gallery_secound_row-img gallery_secound_row-thirdimg">
            <div className="galary_contant_data">
              <h4>Corrosion Protector</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
            </div>
          </div>
          <div className="gallery_third_row-img">
          <div className="galary_contant_data">
              <h4>Corrosion Protector</h4>
              <p>Dorem ipsum dolor sit amet,consectetur<br/> adipiscing elit.</p>
              </div>
          </div>

      </section>
  </>
  )
}

export default Gallery