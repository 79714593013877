import React from "react";
import Carousel from "react-bootstrap/Carousel";
import {ListDot, Services_Works_1, Services_Works_2, Services_Works_3} from '../../assets/images/index';

const Service = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide service_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Industrial<span className="carousel_secound_slide_content-supheading"><br/>Protective Coating Solutions</span></h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide service_slide2">
            <div className="carousel_secound_slide_content">
            <h2>Industrial<span className="carousel_secound_slide_content-supheading"><br/>Protective Coating Solutions</span></h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide service_slide3">
            <div className="carousel_secound_slide_content">
            <h2>Industrial<span className="carousel_secound_slide_content-supheading"><br/>Protective Coating Solutions</span></h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="main_container sevices_solutions">
        <h2>Industrial Solutions</h2>
        <p>
          We 'Naveen Engineering and Speciality Coating Private Limited (NECPL)'
          grab this wonderful opportunity to introduce ourselves as a leading
          solution provider in the Protective Coating for Metal & Concrete
          surfaces. Kindly spare your valuable time to have a glance of our
          profile and the attachment file, so that we can have a chance to serve
          your Organization for a deserving reason.
        </p>
        <p>
          For your kind information, the undersigned has 23 years of competent
          experience in coating field who previously worked with various leading
          MNCs. NECPL is a company that provides latest technical solutions in
          the field of protective coatings with world class products.{" "}
        </p>
        <p>
          NECPL has a team of experienced professionals for providing services
          like protecting. maintaining and repairing stressed surfaces and parts
          of Concrete and Metal.. NECPL employs new technologies in coatings for
          various industries like Steel, Power, Cement, Oil & Gas,
          Petro-chemicals, Refineries & Marine areas It provides "Coat-Yourself
          Solutions" for the following areas :
        </p>

        <div className="sevices_solutions_list">
        <div>
        <h5><img src={ListDot} alt="List"/>Wear Protection</h5>
        <h5><img src={ListDot} alt="List"/>Service life extension</h5>
        </div>
        <div>
        <h5><img src={ListDot} alt="List"/>Erosion Protection</h5>
        <h5><img src={ListDot} alt="List"/>Cavitation Resistance</h5>
        </div>
        <div>
        <h5><img src={ListDot} alt="List"/>Corrosion Protection</h5>
        <h5><img src={ListDot} alt="List"/>Abrasion Resistance</h5>
        </div>
        <div>
        <h5><img src={ListDot} alt="List"/>Non-stick function</h5>
        <h5><img src={ListDot} alt="List"/>Chemical Resistance</h5>
        </div>
       </div>

       <p>With our modern employing technologies, you will be able to achieve the following:-, Less Post maintenance in the coated protecting surfaces + Reduced repair cost + Extended machine life time Our Valued Industrial customers are JSW steels, Sembcorp, Simhapuri Energy, KKNPP, Hyundai Motor, Dalmiya, Ramco, DCW, GPCIL, BHEL, Thermax, SPIC, NLC & so on..</p>

      </section>

      <section className="main_container">
        <h2 className="sevices_solutions-heading">Industrial Works</h2>
        <div className="sevices_solutions_works">
          <div className="sevices_solutions_works-img"><img src={Services_Works_1} alt="service works image"/></div>
          <div className="sevices_solutions_works-img"><img src={Services_Works_2} alt="service works image"/></div>
          <div className="sevices_solutions_works-img"><img src={Services_Works_3} alt="service works image"/></div>
        </div>

      </section>
    </>
  );
};

export default Service;
