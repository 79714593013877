import React, { useEffect } from 'react';

const ElfsightWidget = () => {
  useEffect(() => {
    // Add the Elfsight script to the document head
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.setAttribute('data-use-service-core', '');
    script.defer = true;
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
        {/* <div className="elfsight-app-43c2e8ae-6159-48cd-bfe0-0d4e929551b9 elfsight_fullwidth-section" data-elfsight-app-lazy></div> */}
    </>
  );
};

export default ElfsightWidget;
