import React,{useRef, useState} from "react";
import Carousel from "react-bootstrap/Carousel";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ButtonRightArrow, WelcomImage,Carousel_left_Button, Our_Clients_HYUNDAI,
  Our_Clients_JSW, Our_Clients_DCW, Our_Clients_TAN, Partner_CSI,
  Partner_DIMPLE, Partner_MC, Partner_MCU, Carousel_Right_Button,
  Home_Gallery1, Home_Gallery2, Home_Gallery3, Home_Gallery4,Home_Services_industrial,
  Home_Services_Dam,Home_Services_Water
} from "../../assets/images/index";
import OurWork from "../../components/ourWork";

const Index = () => {
  const [activeWorks, setActiveWorks] = useState("viewAll");
  const activeWorksClick =(activeName)=>{
      setActiveWorks(activeName)    
  }
  const services = [
    {class:"sevices_card1",heading:"Industrial",text:"Protective Coating Solutions",url:Home_Services_industrial},
    {class:"sevices_card2",heading:"Dam Rehabilitation",text:"Specialized Seepage Control Solutions" ,url:Home_Services_Dam},
    {class:"sevices_card3",heading:"Speciality Water",text:"- Proofing Solutions" ,url:Home_Services_Water},
    {class:"sevices_card2",heading:"Dam Rehabilitation",text:"Specialized Seepage Control Solutions" ,url:Home_Services_Dam},
    {class:"sevices_card3",heading:"Speciality Water",text:"- Proofing Solutions" ,url:Home_Services_Water},
    {class:"sevices_card2",heading:"Dam Rehabilitation",text:"Specialized Seepage Control Solutions" ,url:Home_Services_Dam},
    {class:"sevices_card3",heading:"Speciality Water",text:"- Proofing Solutions" ,url:Home_Services_Water},
  ];
  const ourClients = [
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_JSW},
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_DCW},
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_TAN},
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_HYUNDAI},
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_DCW},
    {client:"Borem ipsum dolor sit amet,",imgURL:Our_Clients_JSW}
  ]
  const partners = [
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_DIMPLE},
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_MCU},
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_CSI},
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_MC},
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_DIMPLE},
    {partner:"Borem ipsum dolor sit amet,",imgURL:Partner_DIMPLE},
  ]

  const servicesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const worksSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const clientsSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const serviceSliderRef = useRef(null);
  const workSliderRef = useRef(null);
  const clientsSliderRef = useRef(null);
  const partnersSliderRef = useRef(null);

  const prevClick = () => {
    serviceSliderRef.current.slickPrev();
  };
  const nextClick = () => {
    serviceSliderRef.current.slickNext();
  };

  const prevWorksClick = () => {
    workSliderRef.current.slickPrev();
  };
  const nextWorksClick = () => {
  workSliderRef.current.slickNext();
  };

  const prevclientsClick = () => {
    clientsSliderRef.current.slickPrev();
  };
  const nextclientsClick = () => {
  clientsSliderRef.current.slickNext();
  };

  const prevPartnersClick = () => {
    partnersSliderRef.current.slickPrev();
  };
  const nextPartnersClick = () => {
    partnersSliderRef.current.slickNext();
  };
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide home_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Dam Rehabilitation</h2>
              <h5>We Repair! Don't Replace!</h5>
              <button>
                Know More <img src={ButtonRightArrow} width={10} height={17} />
              </button>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide home_slide2">
            <div className="carousel_secound_slide_content">
              <h2>Dam Rehabilitation</h2>
              <h5>We Repair! Don't Replace!</h5>
              <button>
                Know More <img src={ButtonRightArrow} width={10} height={17} />
              </button>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide home_slide3">
            <div className="carousel_secound_slide_content">
              <h2>Dam Rehabilitation</h2>
              <h5>We Repair! Don't Replace!</h5>
              <button>
                Know More <img src={ButtonRightArrow} width={10} height={17} />
              </button>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="main_container home_welcom_section">
        <div className="home_welcom_section-content">
          <h2>Welcome to NEC</h2>
          <p>
            We "Naveen Engineering and Speciality Coating Private Limited
            (NECPL) a leading solution provider with world class products in the
            below sectors, With 23+ Years of competent experience in coating
            field who previously worked with various leading MNCs.
          </p>
          <button>Read More</button>
        </div>
        <div className="home_welcom_section-img">
          <img
            src={WelcomImage}
            alt="Welcom Image"
            width="100%"
          />
        </div>
      </section>

      <section className="main_container">
        <header className="home_service-header">
          <h2>
            We provide more than just 
            quality services
          </h2>
          <div className="home_service-header_arrowbtn">
            <button onClick={prevClick}>
              <img src={Carousel_left_Button} alt="Carousel_left_Button" />
            </button>
            <button onClick={nextClick}>
              <img src={Carousel_Right_Button} alt="Carousel_Right_Button" />
            </button>
          </div>
        </header>
        <Slider ref={serviceSliderRef} {...servicesSettings}>
          {services?.map((data, index) => {
            return (
              <div key={index} className="home_service-image">
                <img src={data.url} alt="service-img" />
                <div className="home_service-image-content">
                  <h4>{data?.heading}</h4>
                  <p>{data?.text}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
      <OurWork/>

      {/* <section className="home_our-works">
        <header className="home_our-works-header">
          <h4
            onClick={() => activeWorksClick("viewAll")}
            className={activeWorks === "viewAll" ? "active_works" : ""}
          >
            View All
          </h4>
          <h4
            onClick={() => activeWorksClick("industrial")}
            className={activeWorks === "industrial" ? "active_works" : ""}
          >
            INDUSTRIAL SOLUTIONS
          </h4>
          <h4
            onClick={() => activeWorksClick("waterProofing")}
            className={activeWorks === "waterProofing" ? "active_works" : ""}
          >
            WATER PROOFING
          </h4>
          <h4
            onClick={() => activeWorksClick("dam")}
            className={activeWorks === "dam" ? "active_works" : ""}
          >
            DAM REHABILITATION
          </h4>
        </header>
        <div className="home_our-works-content">
          <h2>Our Works</h2>
          <div>
            <Slider ref={workSliderRef} {...worksSettings}>
              <div>
                <div className="home_our-works-content-card">
                  <div className="home_our-works-content-card-image "></div>
                  <h5>Industrial Works</h5>
                </div>
              </div>

              <div>
                <div className="home_our-works-content-card">
                  <div className="home_our-works-content-card-image sevices_card1"></div>
                  <h5>NLC Works</h5>
                </div>
              </div>

              <div>
                <div className="home_our-works-content-card">
                  <div className="home_our-works-content-card-image sevices_card2"></div>
                  <h5>DAM</h5>
                </div>
              </div>

              <div>
                <div className="home_our-works-content-card">
                  <div className="home_our-works-content-card-image sevices_card3"></div>
                  <h5>Kodaiyar Dam</h5>
                </div>
              </div>

              <div>
                <div className="home_our-works-content-card">
                  <div className="home_our-works-content-card-image sevices_card3"></div>
                  <h5>Kodaiyar Dam</h5>
                </div>
              </div>
            </Slider>
          </div>

          <div className="home_our-works-content-btns">
            <img
              src={Carousel_left_Button}
              onClick={prevWorksClick}
              alt="Carousel_left_Button"
            />
            <img
              src={Carousel_Right_Button}
              onClick={nextWorksClick}
              alt="Carousel_Right_Button"
            />
          </div>
        </div>
      </section> */}

      <section className="main_container">
        <header className="home_our_clients-header">
          <div>
            <h2>Our Clients</h2>
            <p>
              Borem ipsum dolor sit amet, consectetur <br />
              adipiscing elit.
            </p>
          </div>
          <div className="home_our_clients-header_arrowbtn">
            <img
              src={Carousel_left_Button}
              onClick={prevclientsClick}
              alt="Carousel_left_Button"
            />
            <img
              src={Carousel_Right_Button}
              onClick={nextclientsClick}
              alt="Carousel_Right_Button"
            />
          </div>
        </header>
        <div className="home_our_clients-content">
          <Slider ref={clientsSliderRef} {...clientsSettings}>
            {ourClients?.map((data, index) => {
              return (
                <div key={index}>
                  <div className="home_our_clients-content-data">
                    <div className="home_our_clients-content-data-img">
                      <img src={data?.imgURL} alt="our client image" />
                    </div>
                    <p>{data?.client}</p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="main_container">
        <header className="home_our_clients-header">
          <div>
            <h2>Authorised Partners </h2>
            <p>
              Borem ipsum dolor sit amet, consectetur <br />
              adipiscing elit.
            </p>
          </div>
          <div className="home_our_clients-header_arrowbtn">
            <img src={Carousel_left_Button} onClick={prevPartnersClick} alt="Carousel_left_Button" />
            <img src={Carousel_Right_Button} onClick={nextPartnersClick} alt="Carousel_Right_Button" />
          </div>
        </header>
        <div className="home_our_clients-content">
          <Slider ref={partnersSliderRef} {...clientsSettings}>
            {partners?.map((data, index) => {
              return (
                <div key={index}>
                  <div className="home_our_clients-content-data">
                    <div className="home_our_clients-content-data-img">
                      <img src={data?.imgURL} alt="our client image" />
                    </div>
                    <p>{data?.partner}</p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="main_container">
        <header className="home_gallery_header">
          <h2>Gallery</h2>
          <button>
            View all <img src={ButtonRightArrow} width={10} height={17} />
          </button>
        </header>
        <div className="home_gallery_contennt">
          <div className="home_gallery_contennt-img">
            <img
              src={Home_Gallery1}
              alt="Home_Gallery"
              width="100%"
              height="100%"
            />
          </div>
          <div className="home_gallery_contennt-img">
            <img
              src={Home_Gallery2}
              alt="Home_Gallery"
              width="100%"
              height="100%"
            />
          </div>
          <div className="home_gallery_contennt-img">
            <img
              src={Home_Gallery3}
              alt="Home_Gallery"
              width="100%"
              height="100%"
            />
          </div>
          <div className="home_gallery_contennt-img">
            <img
              src={Home_Gallery4}
              alt="Home_Gallery"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
