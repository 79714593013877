import React,{useRef, useState} from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Carousel_left_Button, Carousel_Right_Button} from "../assets/images/index";

const OurWork = () => {
    const [activeWorks, setActiveWorks] = useState("viewAll");
    const activeWorksClick =(activeName)=>{
        setActiveWorks(activeName)
    }

    const worksSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const workSliderRef = useRef(null);
  
    const prevWorksClick = () => {
      workSliderRef.current.slickPrev();
    };
    const nextWorksClick = () => {
    workSliderRef.current.slickNext();
    };
  
  return (
    <section className="home_our-works">
    <header className="home_our-works-header">
      <h4
        onClick={() => activeWorksClick("viewAll")}
        className={activeWorks === "viewAll" ? "active_works" : ""}
      >
        View All
      </h4>
      <h4
        onClick={() => activeWorksClick("industrial")}
        className={activeWorks === "industrial" ? "active_works" : ""}
      >
        INDUSTRIAL SOLUTIONS
      </h4>
      <h4
        onClick={() => activeWorksClick("waterProofing")}
        className={activeWorks === "waterProofing" ? "active_works" : ""}
      >
        WATER PROOFING
      </h4>
      <h4
        onClick={() => activeWorksClick("dam")}
        className={activeWorks === "dam" ? "active_works" : ""}
      >
        DAM REHABILITATION
      </h4>
    </header>
    <div className="home_our-works-content">
      <h2>Our Works</h2>
      <div>
        <Slider ref={workSliderRef} {...worksSettings}>
          <div>
            <div className="home_our-works-content-card">
              <div className="home_our-works-content-card-image "></div>
              <h5>Industrial Works</h5>
            </div>
          </div>

          <div>
            <div className="home_our-works-content-card">
              <div className="home_our-works-content-card-image sevices_card1"></div>
              <h5>NLC Works</h5>
            </div>
          </div>

          <div>
            <div className="home_our-works-content-card">
              <div className="home_our-works-content-card-image sevices_card2"></div>
              <h5>DAM</h5>
            </div>
          </div>

          <div>
            <div className="home_our-works-content-card">
              <div className="home_our-works-content-card-image sevices_card3"></div>
              <h5>Kodaiyar Dam</h5>
            </div>
          </div>

          <div>
            <div className="home_our-works-content-card">
              <div className="home_our-works-content-card-image sevices_card3"></div>
              <h5>Kodaiyar Dam</h5>
            </div>
          </div>
        </Slider>
      </div>

      <div className="home_our-works-content-btns">
        <img
          src={Carousel_left_Button}
          onClick={prevWorksClick}
          alt="Carousel_left_Button"
        />
        <img
          src={Carousel_Right_Button}
          onClick={nextWorksClick}
          alt="Carousel_Right_Button"
        />
      </div>
    </div>
  </section>
  )
}

export default OurWork