import React,{useState} from 'react';
import Carousel from "react-bootstrap/Carousel";
import {Contact_Call, Contact_Msg, Contact_Location} from '../../assets/images/index'
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from '@emailjs/browser'
const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: ''
  });
  const [formDataError, setFormDataError] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }

    setFormDataError({
      ...formDataError,
      [name]:""
    })
  };
  const validation = () => {
    let valid = true;
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const callTest = /^[0-9\b]+$/;
    let errors = {};

    if (formData.name.trim() === "") {
        valid = false;
        errors["name"] = "User Name Is Required";
    }

    if (!callTest.test(formData.phoneNumber)||formData.phoneNumber.length !== 10) {
      if(formData.phoneNumber.length==0){
        valid = false;
        errors["phoneNumber"] = "Phone Number Is Required";
      }else{
        valid = false;
        errors["phoneNumber"] = "Invalid Phone Number";
      }
    }

    if (!emailTest.test(formData.email)) {
      if (formData.email.trim() === "") {
        valid = false;
        errors["email"] = "Email Is Required";
      }else{
        valid = false;
        errors["email"] = "Invalid Email";
      }
    }

    if (formData.message.trim() === "") {
        valid = false;
        errors["message"] = "User Message Is Required";
    }

    setFormDataError(errors);
    return valid;
};


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if(validation()){
      console.log('Form data submitted:', formData);

      emailjs
      .sendForm('service_ynw06k7', 'template_awg71ml', form, {
        publicKey: 'LIaZmQIj5NSBVDOjW',
      })
      .then(
        () => {
          alert("Your Message Successfully Sent!")
        },
        (error) => {
          console.log('FAILED...', error);
          alert(error)
        },
      );

      // Reset form fields
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        message: ''
      });
    }
  };

  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide contact_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Contact Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide contact_slide2">
            <div className="carousel_secound_slide_content">
              <h2>Contact Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide  contact_slide3">
            <div className="carousel_secound_slide_content">
              <h2>Contact Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="contact_background-container">
        <div className=" contact_background">
          <div className="contact_heading">
            <h2>Contact Us</h2>
            <h5>
              Feel Free to contact us anytime. <br /> we will get back to you as
              soon as we can
            </h5>
          </div>
          <section className="contact_message_conatiner">
          <div className="contact_message">
            <div className="contact_message-form">
              <form onSubmit={handleSubmit}>
                <div>
                  <h2>Send us a Message</h2>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    // className={formDataError.name && "error_input"}
                  />
                  <p>{formDataError.name && formDataError.name}</p>
                </div>
                <div>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    maxLength={10}
                    placeholder="Phone number"
                    // className={formDataError.phoneNumber && "error_input"}
                  />
                  <p>
                    {formDataError.phoneNumber && formDataError.phoneNumber}
                  </p>
                </div>
                <div>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="email"
                    // className={formDataError.email && "error_input"}
                  />
                  <p>{formDataError.email && formDataError.email}</p>
                </div>
                <div>
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Write Message"
                    // className={formDataError.message && "error_input"}
                  ></textarea>
                  <p>{formDataError.message && formDataError.message}</p>
                </div>
                <button type="submit">Send Message</button>
              </form>
            </div>
          </div>
          </section>
        </div>
      </section>

      <section className="main_container contact_container">
        <h2>Contact Information</h2>
        <section className="contact_information">
          <div className="contact_information-data">
            <img src={Contact_Call} alt="call image" />
            <div>
              <p>+91 98402 72131</p>
            </div>
          </div>
          <div className="contact_information-data">
            <img src={Contact_Msg} alt="call image" />
            <div>
              <p>info@naveenengg.in</p>
            </div>
          </div>
          <div className="contact_information-data">
            <img src={Contact_Location} alt="call image" />
            <div>
              <p>
                F4,Sree Hari Tower,
                <br />
                Rainbow Colony, Kelambakkam,
                <br />
                Kanchipuram, Tamil Nadu - 603 103
              </p>
            </div>
          </div>
        </section>
      </section>

      {/* <header className="main_container contact_message_header">
        <h2>Send us a message</h2>
        <h2>View Map</h2>
      </header>
      <section className="main_container contact_message_container">
        <h2>Send us a message</h2>
        <div className="contact_message">
          <div className="contact_message-form">
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className={formDataError.name && "error_input"}
                />
                <p>{formDataError.name && formDataError.name}</p>
              </div>
              <div>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Phone number"
                  className={formDataError.phoneNumber && "error_input"}
                />
                <p>{formDataError.phoneNumber && formDataError.phoneNumber}</p>
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="email"
                  className={formDataError.email && "error_input"}
                />
                <p>{formDataError.email && formDataError.email}</p>
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write Message"
                  className={formDataError.message && "error_input"}
                ></textarea>
                <p>{formDataError.message && formDataError.message}</p>
              </div>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
        <h2>View Map</h2>
        <div className="contact_map">
          <div className="contact_map-data">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253.6198421389901!2d80.20223537423144!3d12.795553018217216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5250a4c13c2383%3A0xc47a30bc604a3e77!2sSri%20Hari%20Towers!5e1!3m2!1sen!2sin!4v1716033875544!5m2!1sen!2sin"
              width="600"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Contact