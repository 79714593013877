import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import { Carousel_left_Button, Carousel_Right_Button} from "../../assets/images/index";
import OurWork from '../../components/ourWork';


const OurWorks = () => {
  return (<>
        <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide works_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Our Works</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide works_slide2">
            <div className="carousel_secound_slide_content">
              <h2>Our Works</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide works_slide3">
            <div className="carousel_secound_slide_content">
              <h2>Our Works</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* <section className="home_our-works our_works-container">
        <header className="home_our-works-header">
          <h4>View All</h4>
          <h4>INDUSTRIAL SOLUTIONS</h4>
          <h4>WATER PROOFING</h4>
          <h4>DAM REHABILITATION</h4>
        </header>
        <div className="home_our-works-content">
          <h2>Our Works</h2>

          <div className="home_our-works-content-card_container">
          <div className="home_our-works-content-card">
            <div  className="home_our-works-content-card-image ">
            </div>
            <h5>Industrial Works</h5>
          </div>
          <div className="home_our-works-content-card">
            <div  className="home_our-works-content-card-image sevices_card1">
            </div>
            <h5>NLC Works</h5>
          </div>
          <div className="home_our-works-content-card">
            <div  className="home_our-works-content-card-image sevices_card2">
            </div>
            <h5>DAM</h5>
          </div>
          <div className="home_our-works-content-card">
            <div  className="home_our-works-content-card-image sevices_card3">
            </div>
            <h5>Kodaiyar Dam</h5>
          </div>

          </div>

          <div className="home_our-works-content-btns">
          <img src={Carousel_left_Button} alt="Carousel_left_Button"/>
          <img src={Carousel_Right_Button} alt="Carousel_Right_Button"/>
          </div>
        </div>
      </section> */}
      <OurWork/>
      
  </>
  )
}

export default OurWorks