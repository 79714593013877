import React from "react";
import Carousel from "react-bootstrap/Carousel";
import {Feature_FoodGrade, Feature_metal, Feature_Rubber, Feature_Concrete, Feature_Apply,
  Feature_Approved, Feature_Enhancing, Feature_Resistance, Feature_Weare_Resistance,
  Feature_Elastomeric, Feature_Antifouling, Feature_ErosionResistance, Feature_CorrosionResitant,
  Feature_SaltResistant, Feature_Repair

} from "../../assets/images/index"

const Features = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide feature_slide1">
            <div className="carousel_secound_slide_content">
              <h2>Special Features</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide feature_slide2">
            <div className="carousel_secound_slide_content">
              <h2>Special Features</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide feature_slide3">
            <div className="carousel_secound_slide_content">
              <h2>Special Features</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      <section className="main_container">
        <div className="feature_card-container">
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_FoodGrade} alt="Feature image"/></div>
            <h3>Non-toxic/Food Grade</h3>
            <p>Solvent-free, FDA-approved for pharma & food Contact, EU 1935/2004 conforming </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_metal} alt="Feature image"/></div>
            <h3>Bonding to metal</h3>
            <p>Sticks permanently to steet stainless steel, alloy, aluminium, duplex, hardox... </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Rubber} alt="Feature image"/></div>
            <h3>Bonding to Rubber</h3>
            <p>Adhesion to most rubber substrates is beyond the cohesion of the rubber itself</p>
          </div>

          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Concrete} alt="Feature image"/></div>
            <h3>Bonding to Concrete</h3>
            <p>Will Permanently seal developing crack in concrete of up to 3 mm of width</p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Apply} alt="Feature image"/></div>
            <h3>Professional To Apply</h3>
            <p>Sprayable like paint, no installation requerred , fast drying </p>
          </div>    
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Approved} alt="Feature image"/></div>
            <h3>Outdoor Approved</h3>
            <p>Weather Resistance, frost stable/heat proof, impermeable</p>
          </div>

          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Enhancing} alt="Feature image"/></div>
            <h3>Slide Enhancing</h3>
            <p>Better surface fit in value compared to UHMWPE and 5 times more wear Resistant</p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Resistance} alt="Feature image"/></div>
            <h3>Cavitation of Resistance </h3>
            <p>significantly reduces of substrate in hydrodynamic applications </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Weare_Resistance} alt="Feature image"/></div>
            <h3>Ultra-Wear Resistant</h3>
            <p>Upto 50 times more wear resistant compared to leading epoxy-ceramic brands </p>
          </div>

          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Elastomeric} alt="Feature image"/></div>
            <h3>Elastomeric</h3>
            <p>Rubber-like characrieristics but far superior physical properties and wear resistant </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Antifouling} alt="Feature image"/></div>
            <h3>Antifouling Feature</h3>
            <p>Easy to clean resistant mold & fungus hygienic</p>
          </div>    
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_ErosionResistance} alt="Feature image"/></div>
            <h3>Erosion Resistant</h3>
            <p>Up to 25% more erosion resistant compared to duplex steel (AISI 316)</p>
          </div>

          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_CorrosionResitant} alt="Feature image"/></div>
            <h3>Corrosion Resistant</h3>
            <p>Approved for 6000 hours salt-spray chamber testing without failure </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_SaltResistant} alt="Feature image"/></div>
            <h3>Salt Resistant</h3>
            <p>Salt water resistant corrosion protection 700 meter diving depth </p>
          </div>
          <div className="feature_card">
            <div className="feature_card-image"><img src={Feature_Repair} alt="Feature image"/></div>
            <h3>Repair/ Over coatable</h3>
            <p>At anytime, at anytime at any place, simply roughen, clean, prime & coat </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
