import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import {ListDot, Expert_1, Expert_2, Expert_3, Expert_f, Expert_i, Expert_t, Expert_l} from '../../assets/images/index'
const About = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <div className="carousel_secound_slide about_slide1">
            <div className="carousel_secound_slide_content">
              <h2>About Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide about_slide2">
            <div className="carousel_secound_slide_content">
              <h2>About Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel_secound_slide about_slide3">
            <div className="carousel_secound_slide_content">
              <h2>About Us</h2>
              <h5>We Repair! Don't Replace!</h5>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="main_container about_welcome_section">
        <h2>
          Welcome to Naveen Engineering and <br />
          Speciality Coating Pvt Ltd - Coating Solutions Company
        </h2>
        <p>
          We 'Naveen Engineering and Speciality Coating Private Limited (NECPL)'
          grab this wonderful opportunity to introduce ourselves as a leading
          solution provider in the Protective Coating for Metal & Concrete
          surfaces. Kindly spare your valuable time to have a glance of our
          profile and the attachment file, so that we can have a chance to serve
          your Organization for a deserving reason.
        </p>
        <p>
          For your kind information, the undersigned has 23 years of competent
          experience in coating field who previously worked with various leading
          MNCs.
        </p>
        <p>
          NECPL is a company that provides latest technical solutions in the
          field of protective coatings with world class products. NECPL has a
          team of experienced professionals for providing services like
          protecting. maintaining and repairing stressed surfaces and parts of
          Concrete and Metal..
        </p>
        <p>
          NECPL employs new technologies in coatings for various industries like
          Steel, Power, Cement, Oil & Gas, Petro-chemicals, Refineries & Marine
          areas It provides "Coat-Yourself Solutions" for the following areas :
        </p>
       <div className="about_welcome_section_list">
        <div>
        <h5><img src={ListDot} alt="List"/>Corrosion Protector</h5>
        <h5><img src={ListDot} alt="List"/>Damage/Leak repair</h5>
        </div>
        <div>
        <h5><img src={ListDot} alt="List"/>Wear protection(Erosion, Impact, Abrasion)</h5>
        <h5><img src={ListDot} alt="List"/>Maximize production outputt</h5>
        </div>
        <div>
        <h5><img src={ListDot} alt="List"/>Noise abatement</h5>
        </div>
       </div>
        <p>
          With our modern employing technologies, you will be able to achieve
          the following:-, Less Post maintenance in the coated protecting
          surfaces + Reduced repair cost + Extended machine life time Our Valued
          Industrial customers are JSW steels, Sembcorp, Simhapuri Energy,
          KKNPP, Hyundai Motor, Dalmiya, Ramco, DCW, GPCIL, BHEL, Thermax, SPIC,
          NLC & so on..
        </p> 
      </section>

      <section className="about_experts">
        <h2>Our Experts</h2>
        <div className="about_experts-card-container">
          <div className="about_experts-card">
            <div className="about_experts-card_img-container">
              <div className="about_experts-card_img">
                <img src={Expert_1} alt="experts img"/>
              </div>
            </div>
            <h4>Linda Willis</h4>
            <h5>Managing Director</h5>
            <div className="about_experts-socialmedia">
              <img src={Expert_f} alt="faceboock"/>
              <img src={Expert_t} alt="twitter"/>
              <img src={Expert_l} alt="linkedin"/>
              <img src={Expert_i} alt="insta"/>
            </div>
        </div>

        
        <div className="about_experts-card">
            <div className="about_experts-card_img-container">
              <div className="about_experts-card_img">
                <img src={Expert_2} alt="experts img"/>
              </div>
            </div>
            <h4>Linda Willis</h4>
            <h5>Managing Director</h5>
            <div className="about_experts-socialmedia">
              <img src={Expert_f} alt="faceboock"/>
              <img src={Expert_t} alt="twitter"/>
              <img src={Expert_l} alt="linkedin"/>
              <img src={Expert_i} alt="insta"/>
            </div>
            </div>

            <div className="about_experts-card">
            <div className="about_experts-card_img-container">
              <div className="about_experts-card_img">
                <img src={Expert_3} alt="experts img"/>
              </div>
            </div>
            <h4>Linda Willis</h4>
            <h5>Managing Director</h5>
            <div className="about_experts-socialmedia">
              <img src={Expert_f} alt="faceboock"/>
              <img src={Expert_t} alt="twitter"/>
              <img src={Expert_l} alt="linkedin"/>
              <img src={Expert_i} alt="insta"/>
            </div>
            </div>

        </div>
      </section>
    </>
  );
};

export default About;
