import React,{useEffect, useState, useRef} from 'react'
import NEC_Logo from '../assets/images/NEC_Logo.svg'
import { NavLink } from 'react-router-dom';
import{Call,Mesg,Logo} from '../assets/images/index.jsx'
import { useLocation } from 'react-router-dom';
import { Popover, OverlayTrigger, Button, Overlay } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const [callShow, setCallShow] = useState(false);
  const [mailShow, setMailShow] = useState(false);
  const callRef = useRef(null);
  const mailRef = useRef(null);

  const handleClickOutside = (event) => {
    if (callRef.current && !callRef.current.contains(event.target)) {
      setCallShow(false);
    }
    if (mailRef.current && !mailRef.current.contains(event.target)) {
      setMailShow(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(()=>{
    setIsOpen(false)
  },[pathname])

  const callPopover = (
    <Popover id="popover-positioned-bottom">
     <div className="header_popover">
     <p>+91 98402 72131</p>
     </div>
    </Popover>
  );
  const mailPopover = (
    <Popover id="popover-positioned-bottom">
     <div  className="header_popover">
     <p>info@naveenengg.in</p>
     </div>
    </Popover>
  );
  
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <nav className="header_navbar">
      <div className="header_navbar-toggler-container">
      <div className="header_navbar-logo">
      <img src={Logo} width="100%" alt='NEC logo' />
      </div>
      <button className="header_navbar-toggler" onClick={toggleMenu}>
      {isOpen ? '✖' : '☰'}
    </button>
      </div>

    <div  className={`header_navbar-navlinks ${isOpen ? 'open_nav' : ''}`}>
      <NavLink to="/" exact activeClassName="active">
        Home
      </NavLink>
      <NavLink to="/about" exact activeClassName="active">
      About Us
      </NavLink>
      <NavLink to="/services" exact activeClassName="active">
      Services
      </NavLink>
      <NavLink to="/works" exact activeClassName="active">
        Our Works
      </NavLink>
      <NavLink to="/gallery" exact activeClassName="active">
        Gallery
      </NavLink>
      <NavLink to="/features" exact activeClassName="active">
        Special Features
      </NavLink>
      <NavLink to="/contact" exact activeClassName="active">
        Contact Us
      </NavLink>{" "}
      <div className="header_navbar-icons">
      <img
        ref={callRef}
        src={Call}
        onClick={() => { setCallShow(!callShow); setMailShow(false); }}
        width="15"
        height="15"
        alt="Call icon"
      />
      <Overlay target={callRef.current} show={callShow} placement="bottom">
        {callPopover}
      </Overlay>

      <img
        ref={mailRef}
        src={Mesg}
        onClick={() => { setMailShow(!mailShow); setCallShow(false); }}
        width="15"
        height="15"
        alt="Mail icon"
      />
      <Overlay target={mailRef.current} show={mailShow} placement="bottom">
        {mailPopover}
      </Overlay>
      </div>
    </div>
  </nav>
  )
}

export default Header