import React from 'react';
import { RouterProvider } from 'react-router-dom';
import Routers from './routes/router';
import ElfsightWidget from './components/ElfsightWidget';
import './App.css';

const App = () => {
  return (
    <>
      <RouterProvider router={Routers} />
      <ElfsightWidget />
    </>
  );
};

export default App;
