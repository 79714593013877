import React, { useRef, useEffect } from 'react'
import Footer from './footer';
import Header from './header';
import { useLocation } from 'react-router-dom';

const AuthLayout = ({children,...props}) => {
    console.log(props,"props")
    const location = useLocation();
    const mainRef = useRef(null);
  
    useEffect(() => {
      if (mainRef.current) {
        mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [location]);
  return (
    <>
      <div ref={mainRef}>
      <Header/>
      {children}
      <Footer/>
      </div>
    </>
  );
}

export default AuthLayout