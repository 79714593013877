export const Routes = {
  emptyPath: '/',
  home:{
    path:'/home',
    active:"home",
  },
  about:{
    path:'/about',
    active:"about",
  },
  works:{
    path:'/works',
    active:"works",
  },
  services:{
    path:'/services',
    active:"services",
  },
  gallery:{
    path:'/gallery',
    active:"gallery",
  },
  features:{
    path:'/features',
    active:"features",
  },
  contact:{
    path:'/contact',
    active:"contact",
  }
};
